import { render, staticRenderFns } from "./tenet.vue?vue&type=template&id=2bf479c7&scoped=true&"
import script from "./tenet.vue?vue&type=script&lang=ts&"
export * from "./tenet.vue?vue&type=script&lang=ts&"
import style0 from "./tenet.vue?vue&type=style&index=0&id=2bf479c7&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2bf479c7",
  null
  
)

export default component.exports